<template>
  <footer class="footer">
    <div class="footer-top d-flex flex-row align-center justify-between">
      <div class="left d-flex flex-row align-center">
        <div class="left__item">
          <h5>Address</h5>
          <p>Roses Republic. Nieuwe <span>Heschweg 290, 5342 NM, Oss</span></p>
        </div>
        <div class="left__item">
          <h5>Email</h5>
          <p>
            <a href="mailto:hello@rosesrepublic.com">hello@rosesrepublic.com</a>
          </p>
        </div>
        <div class="left__item">
          <h5>Phone</h5>
          <p><a href="tel:+085-0861012">085-0861012</a></p>
        </div>
      </div>
      <div class="right d-flex flex-row align-center">
        <p>
          <router-link to="/">Home</router-link>
        </p>
        <div class="dot"></div>
        <p>
          <router-link to="/shop">Shop</router-link>
        </p>
        <div class="dot"></div>
        <p>
          <router-link to="/contact-us">Contact us</router-link>
        </p>
      </div>
    </div>
    <div class="footer-bottom d-flex flex-row align-center justify-between">
      <h4>© 2020 Roses Republic - All rights reserved</h4>
      <h4>
        <router-link to="/terms-conditions">Terms & Condition</router-link>
      </h4>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
